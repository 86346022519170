import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import GlobalQuickLinks from "@components/globalQuickLinks"

import {
  LearnMoreButton,
  QuickLinkButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const TwitterPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Twitter Follower Links | Jimmy Ether"
        ogDescription="Quick links to things I've recently discussed on Twitter."
        image="https://jimmyether.com/images/jimmy-ether-on-stage.jpg"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h1>Quick Links</h1>
            <p>
              Thanks for visiting from Twitter. Here are some links to things I've been posting about.
            </p>
            <GlobalQuickLinks />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default TwitterPage
